import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./index.css";
import App from "./App";
import ScrollToTop from "./widgets/ScrollToTop";
// providers
import { CategoriesProvider } from "./context/categoriesContext";
import { WishlistProvider } from "./context/wishlistContext";
import { CartProvider } from "./context/cartContext";
import { EditPurchaseProvider } from "./context/editPurchaseContext";
import NavBar from "./component/Menu/navBar";

import { AuthProvider } from "./component/Account/AuthContext";

class Root extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <AuthProvider>
                    <CategoriesProvider>
                        <CartProvider>
                            <WishlistProvider>
                                <EditPurchaseProvider>
                                    <ScrollToTop />

                                    <ToastContainer autoClose={2500} />
                                    <NavBar />
                                    <Routes>
                                        <Route path="*" element={<App />} />
                                    </Routes>
                                </EditPurchaseProvider>
                            </WishlistProvider>
                        </CartProvider>
                    </CategoriesProvider>
                </AuthProvider>
            </BrowserRouter>
        );
    }
}

ReactDOM.render(<Root />, document.getElementById("root"));


//I working trello task already and trello task done inform you