import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./checkout.css";
import cartRequests from "../../requests/cart";
import { isCustomerLogin } from "../../utils/common";
import EyeIcon from "../../assets/icons/EyeIcon";
import CouponBtnIcon from "../../assets/icons/CouponBtnIcon";
import Footer from "../../layouts/footer/Footer";
import CustomLoader from "../Loader/customLoader";
import CardPreview from "../shop/preview";
import { fabric } from "fabric";
import payment from "../../requests/payment";
import AppForm from "./form";
import { useCart } from "../../context/cartContext";
import AccountSignUp from "../Account/AccountSignUp";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
const Cart = () => {
    const [cartItems, setCartItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [signupModal, setSignupModal] = useState(false);
    const navigate = useNavigate();
    const [canvasData, setCanvasData] = useState();
    const [totalPrice, setTotalPrice] = useState(0);
    const [cartProducts, setCartProducts] = useState([]);
    const [modal, setModal] = useState(false);
    // console.log(cartProducts, "cartProducts");
    // console.log(cartItems, "cartResponse");
    const { removeFromCart } = useCart();

    useEffect(() => {
        const customerId = isCustomerLogin();
        if (!customerId) {
            // setSignupModal(true);
        }
    }, []);

    const fetchCartItems = async () => {
        try {
            const customerId = isCustomerLogin();
            let items = [];

            if (!customerId) {
                const localCart = JSON.parse(localStorage.getItem("cart") || "[]");
                items = localCart;
            } else {
                const cartResponse = await cartRequests.getFromCart(customerId);
                items = cartResponse.data;
            }

            setCartItems(items);

            let total = 0;

            const result = items.map((item) => {
                total += item.productDetails.price || 0;

                return {
                    productId: item.product_id,
                    price: item.productDetails.price,
                    name: item.productDetails.name,
                    quantity: 1,
                };
            });

            setCartProducts(result);
            setTotalPrice(parseFloat(total.toFixed(2)));
            setLoading(false);
        } catch (error) {
            console.error("Error fetching cart items:", error);
            setError("Failed to fetch cart items. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCartItems();
    }, []);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://cdn.cardknox.com/ifields/2.15.2408.0801/ifields.min.js";
        script.async = true;

        script.onload = () => {
            // console.log("Cardknox script loaded");
            // console.log(window.setAccount);

            if (window.setAccount) {
                window.setAccount("ifields_developmentdev08073b8572e34fa89346776", "developmentdev", "0.1.2");
            } else {
                console.error("setAccount function not available");
            }
        };

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const handleDelete = async (wooCartItemKey, id) => {
        try {
            const customerId = isCustomerLogin();

            await cartRequests.deleteFromCart(customerId, wooCartItemKey);
            setCartItems(cartItems.filter((item) => item.wooCartItemKey !== wooCartItemKey));
            const itemToRemove = cartItems.find((item) => item.wooCartItemKey === wooCartItemKey);
            removeFromCart({ _id: id });
            if (itemToRemove) {
                setTotalPrice((prevTotal) => prevTotal - itemToRemove.productDetails.price);
            }
        } catch (error) {
            console.error("Error deleting cart item:", error);
        }
    };

    const handlePreview = (customizationData) => {
        console.log(customizationData, "handlePreview");
        const canvasData = JSON.parse(customizationData);
        const _canvas = new fabric.Canvas(null);
        _canvas.loadFromJSON(canvasData, () => {
            _canvas.renderAll();
            const base64Url = _canvas.toDataURL({
                format: "jpeg",
            });
            setCanvasData(base64Url);
            setModal(true);
        });
    };

    if (loading) {
        return (
            <div style={{ width: "100%", height: "100vh", display: "flex", alignItems: "center" }}>
                <CustomLoader />{" "}
            </div>
        );
    }

    if (error) {
        return <div>{error}</div>;
    }

    const handleEdit = (item) => {
        // console.log(item.product_id,"item.product_id");
        // console.log(item._id,"item._id");
        navigate(`/card/${item.product_id}/?cart_item_key=${item._id}`);
    };

    const toggleModal = () => setModal(!modal);
    return (
        <>
            <div className="checkout-container">
                <div className="checkout-sub-container">
                    <div className="order-summary-container">
                        <p className="order-summary-hd">Order Summary</p>
                        <div className="cart-items-container">
                            {(cartItems || []).map((item) => (
                                <div key={item.wooCartItemKey} className="cart-item">
                                    <img
                                        style={{ width: "100px" }}
                                        src={item.customizeItemPreview}
                                        alt="Item preview"
                                        className="cart-item-img"
                                    />
                                    <div className="cart-data-container">
                                        <p className="cart-item-name">{item.productDetails.name}</p>
                                        <p className="cart-item-data">
                                            <span className="cart-item-hd">Music: </span>No
                                        </p>
                                        <p className="cart-item-data">
                                            <span className="cart-item-hd">QR:</span>No
                                        </p>
                                        <p className="cart-item-data">
                                            <span className="cart-item-hd">Size: </span>Vertical (8.5x11)
                                        </p>
                                        <p className="cart-item-price">${item.productDetails.price}</p>
                                    </div>
                                    <div className="cart-icon-wrap">
                                        <div
                                            className="cart-eye-icon"
                                            onClick={() => handlePreview(item.customizationData)}
                                        >
                                            <EyeIcon width={"1em"} height={"1em"} />
                                        </div>
                                        <div
                                            className="cart-edit-icon"
                                            onClick={() => {
                                                handleEdit(item);
                                            }}
                                        >
                                            <i className="fa fa-pencil" aria-hidden="true"></i>
                                            {/* edit */}
                                        </div>
                                        <div
                                            className="cart-delete-icon"
                                            onClick={() => {
                                                handleDelete(item.wooCartItemKey, item._id);
                                            }}
                                        >
                                            <i className="fa fa-trash" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="cart-payment-container">
                        <p className="payment-hd">Payment Details</p>
                        <div className="cart-payment-form">
                            <div className="cart-input-wrap">
                                <AppForm total={totalPrice} products={cartProducts} />
                            </div>
                        </div>
                        {/* Coupon code and other components */}
                    </div>
                </div>
                {/* {canvasData && <CardPreview canvasData={canvasData} enabled={!!canvasData} />} */}
                <Modal isOpen={modal} toggle={toggleModal}>
                    <ModalBody>
                        {/* {canvasData && <img src={canvasData} alt="Customization Preview" style={{ width: "100%" }} />} */}
                        {(cartItems || []).map((item) => (
                            <img className="cart-menu-img" src={item.customizeItemPreview} alt="Item preview" />
                        ))}
                    </ModalBody>
                    <ModalFooter></ModalFooter>
                </Modal>

                <Modal
                    isOpen={signupModal}
                    toggle={() => setSignupModal(!signupModal)}
                    backdrop="static"
                    keyboard={false}
                >
                    <ModalHeader>Sign Up</ModalHeader>
                    <ModalBody>
                        <AccountSignUp />
                    </ModalBody>
                </Modal>
            </div>
            <Footer />
        </>
    );
};

export default Cart;
