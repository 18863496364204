import React, { useEffect, useRef, useState } from "react";
import { hexToCSSFilter, clearCache } from "hex-to-css-filter";
import { WP_BASE_URL } from "../../config";
import defaultCategoryImage from "../../assets/images/category-default-icon.png";
import "./styles/categories.css";
import useLocalStorage from "../../hooks/useLocalStorage";

const CategoryItem = ({ name, color, backgroundColor, _id, imageId, friendlyUrlName }) => {
    const [productsLinks, setProductsLinks] = useState("");
    const divRef = useRef();
    // const [logoColor, setLogoColor] = useLocalStorage("logoColor");

    const handleHover = (enterOrLeave) => {
        const div = divRef.current;
        const colors = {
            bg: enterOrLeave === "enter" ? backgroundColor : "#ffffff",
            principal: enterOrLeave === "enter" ? color : "#000000",
        };

        div.parentElement.style.backgroundColor = colors.bg;

        if (imageId?.path?.includes(".svg")) {
            let logoColor = hexToCSSFilter(colors.principal);
            logoColor = logoColor.filter.replace(";", "");

            div.querySelector("img").style.filter = logoColor;
            div.querySelector(".chi-text").style.color = colors.principal;
        }
    };

    useEffect(() => {
        clearCache();

        const wpLink = `${WP_BASE_URL}/cards/?id=${friendlyUrlName}`;
        const vercelLink = `/categories/${friendlyUrlName}`;

        // const _productsLinksUrl = /(vercel\.app|localhost)/.test(window.location) ? vercelLink : wpLink;
        const _productsLinksUrl = vercelLink;
        // const _productsLinksUrl = "";
        setProductsLinks(_productsLinksUrl);
    }, []);

    // chi = category home item
    return (
        <a
            href={productsLinks}
            target="_parent"
            onMouseEnter={() => handleHover("enter")}
            onMouseLeave={() => handleHover("leave")}
            className="chi-link"
        >
            <div className="chi" ref={divRef}>
                <div className="chi-wrapper">
                    <div className="chi-image">
                        <img src={imageId?.path || defaultCategoryImage} className="img-fluid" alt={name} />
                    </div>

                    <div className="chi-text">{name}</div>
                </div>
            </div>
        </a>
    );
};

export default CategoryItem;
