// import React, { useEffect, useState } from 'react';
// // images
// import playIcon from '../../assets/images/icons/play-icon.svg';
// import pauseIcon from '../../assets/images/icons/pause-icon.svg';
// // custom elements
// import FakeRadioButton from '../Inputs/fakeRadioButton';
// // style
// import './styles/songItem.css';


// const SongItem = ({ songObj, handleChange, selectedSongId, restartAnimation }) => {
//     const { _id, filename, path } = songObj;
//     const [paused, setPaused] = useState(true);
//     const [duration, setDuration] = useState(0);

//     const _handlePlayPause = (forcePause = false) => {
//         const audios = [...document.querySelectorAll('.audio')];
//         const currentAudio = document.getElementById('audio' + _id);

//         for (let a of audios) { a.pause() }

//         if (forcePause) {
//             currentAudio.pause();
//             return;
//         }
//         if (paused) currentAudio.play();
//         else currentAudio.pause();
//     }

//     const handlePlayPauseState = () => {
//         _handlePlayPause();
//         handleChange(songObj);
//         setPaused(!paused);
//     }

//     const handleCheckbox = (checked) => {
//         let obj = checked ? songObj : null;
//         let forcePause = (_id === selectedSongId) && paused;
//         _handlePlayPause(forcePause);
//         handleChange(obj);
//         setPaused(forcePause || !paused);
//     }

//     // set state to pause when select another song 
//     useEffect(() => {
//         if (_id !== selectedSongId) {
//             setPaused(true);
//         }
//     }, [selectedSongId]);

//     // save song duration
//     useEffect(() => {
//         const currentAudio = document.getElementById('audio' + _id);
//         currentAudio.onloadeddata = () => {
//             setDuration(currentAudio.duration);
//         }
//     }, [paused]);

//     // progress bar animation
//     useEffect(() => {
//         const svgLine = document.getElementById('audio-line' + _id);
//         // console.log(svgLine);    

//         if (duration > 0) {
//             svgLine.style.animation = `${duration}s linear 0s infinite normal none ${paused ? 'paused' : 'running'} show100`;
//             svgLine.style.animationName = 'fill-song-bar';
//         }
//     }, [paused, duration, restartAnimation]);


//     console.log(path, "path");

//     return (
//         <div className="song-item-container">
//             <div onClick={handlePlayPauseState} className="song-playpause-btn">
//                 {
//                     _id !== selectedSongId ? (
//                         <img src={playIcon} alt="play" className="play-img" />
//                     ) : (
//                         paused ? <img src={playIcon} alt="play" className="play-img" /> : <img src={pauseIcon} alt="pause" className="pause-img" />
//                     )
//                 }
//             </div>

//             <div className="song-bar-and-name">
//                 <div className={`song-name-title-div ${filename?.length >= 25 ? 'txt-long' : ''}`}>
//                     <span className='song-name-title-span'>{filename}</span>
//                 </div>
//                 <figure className='figure-chart' data-percent="100">
//                     <svg width="157" height="4">
//                         <line
//                             className="audio-line"
//                             id={'audio-line' + _id}
//                             x1="0"
//                             y1="2"
//                             x2="157"
//                             y2="2"
//                         ></line>
//                     </svg>
//                 </figure>
//             </div>

//             <FakeRadioButton
//                 checked={_id === selectedSongId}
//                 className="song-radio-btn"
//                 onCheck={_checked => handleCheckbox(_checked)}
//             />

//             {/* <audio
//                 id={'audio' + _id}
//                 src={path}
//                 className='audio'
//                 style={{ height: 0, width: 0, opacity: 0 }}
//                 loop
//                 preload="metadata"
//             /> */}

//             <audio
//                 id={'audio' + _id}
//                 className="audio"
//                 style={{ height: 0, width: 0, opacity: 0 }}
//                 loop
//                 preload="metadata"
//             > 
//                 <source src={path} type="audio/mpeg" />
//                 Your browser does not support the audio element.
//             </audio>

//         </div>
//     );
// }

// export default SongItem;




import React, { useEffect, useState } from "react";
import playIcon from "../../assets/images/icons/play-icon.svg";
import pauseIcon from "../../assets/images/icons/pause-icon.svg";
import FakeRadioButton from "../Inputs/fakeRadioButton";
import "./styles/songItem.css";

const SongItem = ({ songObj, handleChange, selectedSongId, restartAnimation }) => {
    const { _id, filename, path } = songObj;
    const [paused, setPaused] = useState(true);
    const [duration, setDuration] = useState(0);

    const currentAudioId = `audio${_id}`;
    const currentAudioLineId = `audio-line${_id}`;

    const handlePlayPause = async (forcePause = false) => {
        const currentAudio = document.getElementById(currentAudioId);
        if (!currentAudio) return;

        try {
            if (forcePause) {
                currentAudio.pause();
            } else {
                if (paused) {
                    await currentAudio.play();
                } else {
                    currentAudio.pause();
                }
            }
        } catch (error) {
            console.error("Error playing audio:", error);
        }
    };

    const handlePlayPauseState = () => {
        handlePlayPause();
        handleChange(songObj);
        setPaused((prev) => !prev); // Toggle paused state
    };

    const handleCheckbox = (checked) => {
        const obj = checked ? songObj : null;
        const forcePause = _id === selectedSongId && paused;
        handlePlayPause(forcePause);
        handleChange(obj);
        setPaused(forcePause || !paused);
    };

    // Pause the other songs when a new song is selected
    useEffect(() => {
        if (_id !== selectedSongId) {
            setPaused(true); // Reset paused state when a new song is selected
        }
    }, [selectedSongId]);

    useEffect(() => {
        const currentAudio = document.getElementById(currentAudioId);
        if (currentAudio) {
            currentAudio.onloadeddata = () => setDuration(currentAudio.duration); // Get the duration of the audio
        }
    }, []);

    // Animation for the song progress bar
    useEffect(() => {
        const svgLine = document.getElementById(currentAudioLineId);
        if (duration > 0 && svgLine) {
            svgLine.style.animation = `${duration}s linear 0s infinite normal none ${paused ? "paused" : "running"} show100`;
            svgLine.style.animationName = "fill-song-bar"; // Set the animation to match audio duration
        }
    }, [paused, duration, restartAnimation]);

    // Pause all other songs when this song is selected and played
    useEffect(() => {
        if (_id === selectedSongId && !paused) {
            // Pause other audios that are not the selected one
            const allAudios = document.querySelectorAll('audio');
            allAudios.forEach((audio) => {
                if (audio.id !== currentAudioId) {
                    audio.pause();
                }
            });
        }
    }, [selectedSongId, paused, _id]);

    return (
        <div className="song-item-container">
            <div onClick={handlePlayPauseState} className="song-playpause-btn">
                <img
                    src={paused ? playIcon : pauseIcon}
                    alt={paused ? "play" : "pause"}
                    className={paused ? "play-img" : "pause-img"}
                />
            </div>

            <div className="song-bar-and-name">
                <div className={`song-name-title-div ${filename?.length >= 25 ? "txt-long" : ""}`}>
                    <span className="song-name-title-span">{filename}</span>
                </div>
                <figure className="figure-chart" data-percent="100">
                    <svg width="157" height="4">
                        <line className="audio-line" id={currentAudioLineId} x1="0" y1="2" x2="157" y2="2"></line>
                    </svg>
                </figure>
            </div>

            <FakeRadioButton
                checked={_id === selectedSongId}
                className="song-radio-btn"
                onCheck={(checked) => handleCheckbox(checked)}
            />

            <audio
                id={currentAudioId}
                className="audio"
                style={{ height: 0, width: 0, opacity: 0 }}
                loop
                preload="metadata"
                onPlay={() => setPaused(false)} // Handle play event
                onPause={() => setPaused(true)} // Handle pause event
                onError={(e) => console.error("Audio Error:", e.target.error)}
            >
                <source src={path} />
                Your browser does not support the audio element.
            </audio>
        </div>
    );
};

export default SongItem;



{/* <source src="https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview118/v4/65/07/f5/6507f5c5-dba8-f2d5-d56b-39dbb62a5f60/mzaf_1124211745011045566.plus.aac.p.m4a" type="audio/mp3" />  */ }

