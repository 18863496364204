import React, { useEffect, useState } from "react";
import ModalCancelIcon from "../../assets/icons/modal-cancel-icon";
import SendIcon from "../../assets/reach-out-modal/send-icon";
import WhatsappIcon from "../../assets/reach-out-modal/whatsappIcon";
import PhoneIcon from "../../assets/reach-out-modal/phoneIcon";
import ChatBubbleIcon from "../../assets/reach-out-modal/chatBubbleIcon";
import EmailIcon from "../../assets/reach-out-modal/emailIcon";
import mailRequests from "../../requests/mail";

const ContactUsModal = ({ setModalOpen, modalOpen }) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    async function onSubmit(){
        console.log(firstName, lastName, email, message);
        
        if(firstName == "" ||  lastName == "" || email == "" || message == "") return;

        await mailRequests.sendContactUsMail({firstName, lastName, email, message});
    }

    return (
        <div>
            {" "}
            {/* {modalOpen && ( */}
            <div
                className={`modal fade ${modalOpen ? "show" : ""}`}
                id="staticBackdrop"
                data-bs-backdrop="true"
                data-bs-keyboard="false"
                tabindex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden={!modalOpen}
            >
                <div className="modal-dialog scrollable reach-out-modal-container">
                    <div className="modal-content">
                        <div data-bs-dismiss="modal" aria-label="Close" className="modal-close-btn">
                            <ModalCancelIcon />
                        </div>
                        <div className="modal-body ">
                            <p className="reach-out-hd">We’re here and we care.</p>
                            <p className="reach-out-text">
                                Reach out with any questions, concerns, comments or suggestions.
                            </p>
                            <div className="reach-modal-input-wrap">
                                <div className="reach-text-input-wrap">
                                    <input value={firstName} onChange={(e) => setFirstName(e.target.value)} className="reach-input" type="text" placeholder="First Name" />
                                    <input value={lastName} onChange={(e) => setLastName(e.target.value)} className="reach-input" type="text" placeholder="Last Name" />
                                </div>
                                <input value={email} onChange={(e) => setEmail(e.target.value)} className="reach-input" type="email" name="email" placeholder="Email" />
                                <div className="textarea-icon-wrap">
                                    <textarea value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Message" className="reach-out-text-area"></textarea>
                                    <div onClick={onSubmit} className="send-icon-wrap">
                                        <SendIcon />
                                    </div>
                                </div>
                            </div>
                            <div className="reach-out-btn-wrap">
                                <button className="reach-out-btn">
                                    <WhatsappIcon />
                                    Whatsapp
                                </button>
                                <button className="reach-out-btn">
                                    <PhoneIcon />
                                    <ChatBubbleIcon />
                                    347.765.7824
                                </button>
                                <button className="reach-out-btn">
                                    <EmailIcon />
                                    support@hazmona.com
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* )} */}
        </div>
    );
};

export default ContactUsModal;
