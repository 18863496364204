import { getCustomerID, getUserEmail } from "./token";
import { getToken } from "./token";
import { useLocation } from "react-router-dom";

const isCustomerLogin = () => {
    let customerId = getCustomerID();
    customerId = customerId.replace(/^"(.*)"$/, "$1");
    return customerId ? customerId : false;
};

const customerEmail = () => {
    let customerEmail = getUserEmail();
    return customerEmail ? customerEmail : false;
};
const setDefaultOptions = ({ headers = {}, token, cancelToken }) => {
    const defaultHeaders = {
        "Content-Type": "application/json",
        ...headers,
    };
    if (token) {
        const authToken = getToken();

        if (authToken) {
            defaultHeaders["Authorization"] = `Bearer ${authToken}`;
        }
    }
    return {
        headers: defaultHeaders,
        ...(cancelToken && { cancelToken }),
    };
};

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export { isCustomerLogin, setDefaultOptions, customerEmail };

export default useQuery;
