/**
 * change this every time you will deploy on development or production
 */
const IS_DEVELOPMENT = true;

let API_BASE_URL,
    WP_API_BASE_URL = "",
    WP_BASE_URL = "";

// if (IS_DEVELOPMENT) {
//     //     // development
//     //     API_BASE_URL = "https://newhazmoonapidev.xyz/api";
//     API_BASE_URL = "http://localhost:7000/api";
// } else {
//     API_BASE_URL = "https://newhazmoonapi.xyz/api"; // production
// }

API_BASE_URL = "https://newhazmoonapidev.xyz/api";

export { API_BASE_URL, WP_API_BASE_URL, WP_BASE_URL, IS_DEVELOPMENT };
