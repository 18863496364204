import cartRequests from "../../requests/cart";
import { fabric } from "fabric";
import fieldsRequests from "../../requests/fields";
import { assingObjectsToCanvas, forceRenderUpdate, setCanvasAtResoution, setCustomFontsWhenLoaded, toggleAllFieldsVisibility } from "../../utils/canvas";
import { useEffect, useState } from "react";
import productRequests from "../../requests/products";
import { getUrlQuery } from "../../utils/url";
import { isCustomerLogin } from "../../utils/common";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CustomLoader from "../Loader/customLoader";
import orderRequests from "../../requests/orders";


const ordercarddetails = ({ product ,orderId }) => {

    const [canvas, setCanvas] = useState();
    const [inputsproducts, setInputsproducts] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [enablePreview, setEnablePreview] = useState(false);
    const [productimage, setproductimage] = useState();
    const [productDownloadId, setProductDownloadId] = useState();
    const [productdata, setProductdata] = useState();
    const [InputsValues, setInputsValues] = useState();
    const [image ,setimage] = useState();
    const navigate = useNavigate();

    function onEdit(e, product ) {
        e.preventDefault();
        e.stopPropagation();
        navigate(`/edit-purchase?productId=${product._id}&orderId=${orderId}`)
    }

    // const useQuery = () => {
    //     return new URLSearchParams(useLocation().search);
    // };
    // const query = useQuery();
    // const cart_item_key = query.get("cart_item_key");

    // useEffect(() => {
    //     if (productDownloadId == null) return;

    //     // console.log(productDownloadId, "image");

    //     const CANVAS_SIZE = 800;

    //     const _canvas = new fabric.Canvas("canvas-wrapper", {
    //         selection: false,
    //         imageSmoothingEnabled: false,
    //     });
    //     setCanvas(_canvas);

    //     async function _getProductFields() {
    //         let _productFields;
    //         // console.log(_productFields, "image");

    //         const customerId = isCustomerLogin();
    //         if (cart_item_key) {
    //             const cartData = await cartRequests.getFromCart(customerId);
    //             console.log(cartData,"cartData");
                
    //             if (cartData) {
    //                 const firstCartItem = newcartData?.data[0];
    //                 const customizationDataString = firstCartItem.customizationData;
    //                 const customizationData = JSON.parse(customizationDataString);
    //                 _productFields = customizationData.canvasObjects;
    //             }
    //         } else {
    //             const { customizer_product_id } = productDownloadId ? {} : getUrlQuery();
    //             _productFields = await fieldsRequests.getProductFields(productDownloadId || customizer_product_id);
    //         }
    //         return _productFields;
    //     }


    //     async function resizeAndSetTextOnCanvas() {
    //         const _productFields = await _getProductFields();
    //         // if (!_productFields || !Array.isArray(_productFields) || _productFields.length === 0) return false;
    //         if (_productFields === undefined || _productFields.length === 0) return false;

    //         const imageDimensions = {
    //             width: _productFields[0].originalImageWidth,
    //             height: _productFields[0].originalImageHeight,
    //         };

    //         const _inputsValues = {};
    //         for (let f of _productFields) {
    //             _inputsValues[f._id] = f;
    //         }

    //         setInputsValues(_inputsValues);
    //         await assingObjectsToCanvas(_canvas, _productFields);
    //         toggleAllFieldsVisibility(_canvas, false);
    //         setCanvasAtResoution(_canvas, CANVAS_SIZE, _productFields[0].canvasWidth, imageDimensions);
    //         return true;
    //     }

    //     function setCanvasBackgroundImage(_productData) {
    //         return new Promise((resolve, reject) => {
    //             if (!_productData || !(_productData.filesId && _productData.filesId.pathWithWatermark)) {
    //                 reject(new Error("Invalid product data"));
    //                 return;
    //             }
    //             fabric.Image.fromURL(
    //                 _productData.filesId.pathWithWatermark,
    //                 (img) => {
    //                     const scaleFactor = img.width / _canvas.width;
    //                     const naturalCanvasHeight = img.height / scaleFactor;
    //                     _canvas.setDimensions({ width: _canvas.width, height: naturalCanvasHeight });
    //                     _canvas.setBackgroundImage(img, _canvas.renderAll.bind(_canvas), {
    //                         scaleX: _canvas.width / img.width,
    //                         scaleY: _canvas.height / img.height,
    //                     });
    //                     resolve();
    //                 },
    //                 {
    //                     crossOrigin: "anonymous",
    //                     objectCaching: false,
    //                 }
    //             );
    //         });
    //     }

    //     async function getProductData() {
    //         const { customizer_product_id, id: wooProductId } = productDownloadId ? {} : getUrlQuery();
    //         const _product = await productRequests.getProduct({
    //             productId: productDownloadId || customizer_product_id,
    //             wooProductId,
    //         });
    //         if (!_product) {
    //             throw new Error("Failed to fetch product data");
    //         }
    //         setProductdata(_product);
    //         setIsLoading(true);
    //         return _product;
    //     }

    //     getProductData().then(async (_productData) => {
    //         await resizeAndSetTextOnCanvas();
    //         await setCustomFontsWhenLoaded(_canvas);
    //         await forceRenderUpdate(_canvas);

    //         try {
    //             await setCanvasBackgroundImage(_productData);
    //             forceRenderUpdate(_canvas, true);
    //             setEnablePreview(true);
    //             toggleAllFieldsVisibility(_canvas);
    //             setIsLoading(false);

    //             handleDownload(_canvas);

    //             setProductDownloadId(null);
    //         } catch (error) {
    //             console.error("Error setting background image:", error);
    //         }
    //     }).catch((error) => {
    //         console.error("Error fetching product data:", error);
    //     });

    //     return () => {
    //         if (canvas) {
    //             canvas.dispose();
    //             setCanvas(null);
    //         }
    //     };

    // }, [productDownloadId]);

    // const handleDownload = (canvas) => {
    //     // console.log(canvas ,"canvas");
        
    //     if (!canvas) return;
    //     const dataUrl = canvas.toDataURL("image/png");
    //     const downloadLink = document.createElement("a");
    //     downloadLink.href = dataUrl;
    //     downloadLink.download = `product_${productDownloadId}.png`;
    //     downloadLink.click();

    //     setimage(dataUrl)
    // };

    const onDownload = (e, product) => {
        e.preventDefault();
        const productimageid = product._id;
        setProductDownloadId(productimageid);
        navigate(`/download-files?productId=${product._id}&orderId=${orderId}`)
    };


    return (

        isLoading ? (
            <div style={{ width: "100%", height: "200px", display: "flex", alignItems: "center" }}>
                <CustomLoader />
            </div>
        ) : (
            <div>
                <Link to={`/card/${product?._id}`} className="single-order-box" key={product._id}>
                    <img
                        className="order-image"
                        src={product.filesId.pathWithWatermark}
                        alt=""
                    />
                    {/* <canvas
                        id="dataUrl"
                        style={{ width: "100px", height: "120px", borderRadius: "10px", display: "none" }}
                    ></canvas> */}
                    <div className="order-content">
                        <div className="text-div">
                            <h4 className="mb-0">{product.name}</h4>
                            <div>
                                <span>
                                    <strong>Music:</strong> No
                                </span>
                                <br />
                                <span>
                                    <strong>QR:</strong> Yes
                                </span>
                                <br />
                            </div>
                            <h4 className="bolder">${product.price}</h4>
                        </div>
                        <div className="btn-div">
                            <button className="edit-btn" onClick={(e) => onEdit(e, product)}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14px"
                                    height="14px"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M3 17.46v3.04c0 .28.22.5.5.5h3.04c.13 0 .26-.05.35-.15L17.81 9.94l-3.75-3.75L3.15 17.1c-.1.1-.15.22-.15.36M20.71 7.04a.996.996 0 0 0 0-1.41l-2.34-2.34a.996.996 0 0 0-1.41 0l-1.83 1.83l3.75 3.75z"
                                    />
                                </svg>{" "}
                                Edit
                            </button>
                            <button className="download-btn" onClick={(e) => onDownload(e, product)}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14px"
                                    height="14px"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        fill="currentColor"
                                        d="m12 16l-5-5l1.4-1.45l2.6 2.6V4h2v8.15l2.6-2.6L17 11zm-8 4v-5h2v3h12v-3h2v5z"
                                    />
                                </svg>{" "}
                                Download
                            </button>
                        </div>
                    </div>
                </Link>
            </div>
        )
    )
}

export default ordercarddetails;
