/**
 * ProductList Widget
 */
import React, { useState, useEffect, memo, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { fabric } from "fabric";
import { WP_BASE_URL } from "../config";

// custom components
import LikeIcon from "./LikeIcon";
import IconLoading from "./IconLoading";
import Tooltip from "../component/ToolTips/uncontrolledTooltip";
// utils
import {
    assingObjectsToCanvas,
    setCustomFontsWhenLoaded,
    setCanvasAtResoution,
    forceRenderUpdate,
} from "../utils/canvas";
import postToParent from "../utils/postToParent";
import { getUrlQuery } from "../utils/url";
// custom hooks
import { useWishlist } from "../context/wishlistContext";
// requests
import wishlistRequests from "../requests/wishlist";
// style
import "./styles/productListItem.css";

import { isCustomerLogin } from "../utils/common";
import { useCart } from "../context/cartContext";

const ProductListItem = memo(({ product, big = false, subCategoriesSelected, selectedTags }) => {
    const [heartIconLoading, setHeartIconLoading] = useState(false);
    const [userWPId, setUserWPId] = useState(false);
    const { wishlistItems, addItemToWishlist, removeFromWishlist } = useWishlist();
    const { cartItems, cartQty } = useCart();

    const { pathname } = useLocation();
    const params = useParams();

    const productLinkUrl = useMemo(() => {
        const wpLink = `${WP_BASE_URL}/design-your-card/?id=${product.wooProductId}`;
        const vercelLink = `/card/${product._id}`;

        // Check if the product is in the cart
        const cartItem = Object.values(cartItems).find((cartItem) => cartItem.product_id === product._id);

        // Include cart information in the URL if the product is in the cart
        const wpLinkWithCart = cartItem ? `${wpLink}&cart_item_key=${cartItem._id}` : wpLink;
        const vercelLinkWithCart = cartItem ? `${vercelLink}?cart_item_key=${cartItem._id}` : vercelLink;

        return vercelLink;
    }, [product, cartItems]); // Include cart in the dependency array

    const CANVAS_WIDTH = 220 * (big ? 2 : 1); // px

    // get wordpress userWPId every time we change path (category)
    useEffect(() => {
        const isCustomer = isCustomerLogin();
        // console.log(isCustomer,"isCustomer");

        // const urlQuery = getUrlQuery(); wordpress customer id
        setUserWPId(isCustomer);
    }, [pathname]);

    // console.log(product ,"product");

    useEffect(() => {
        if (product.thumbnailWithPlaceholders || !product.fields?.length) {
            return;
        }
        const canvas = new fabric.Canvas("canvas" + product._id, {
            defaultCursor: "pointer",
            selection: false,
        });
        // setCanvas(canvas);

        async function resizeAnsSetTextOnCanvas() {
            const { fields } = product;

            const imageDimensions = {
                width: fields[0].originalImageWidth,
                height: fields[0].originalImageHeight,
            };

            await assingObjectsToCanvas(canvas, fields);
            setCanvasAtResoution(canvas, CANVAS_WIDTH, fields[0].canvasWidth, imageDimensions);
            return true;
        }

        resizeAnsSetTextOnCanvas().then((resized) => {
            if (resized) {
                setCustomFontsWhenLoaded(canvas).then(() => {
                    forceRenderUpdate(canvas);
                });
            }
        });
    }, [product.thumbnailWithPlaceholders, product.fields?.length]);

    const handleAddToWishlist = async () => {
        if (heartIconLoading || !userWPId) return;
        setHeartIconLoading(true);

        const added = await wishlistRequests.addItemToWishlist({
            customerId: userWPId,
            productId: product.wooProductId,
        });

        if (added.status == "success") {
            console.log("added", added);
            addItemToWishlist(product);
            postToParent({ type: "modify-wishlist-count-ui", wishlistCountData: 1 });
        }

        setHeartIconLoading(false);
    };

    const handleRemoveFromWishlist = async () => {
        if (heartIconLoading || !userWPId) return;
        setHeartIconLoading(true);

        const deleted = await wishlistRequests.deleteWishlistItem({
            customerId: userWPId,
            productId: product.wooProductId,
        });

        console.log("deleted");

        if (deleted.status == "success") {
            removeFromWishlist(product);
            postToParent({ type: "modify-wishlist-count-ui", wishlistCountData: -1 });
        }
        setHeartIconLoading(false);
    };

    return (
        <div className="product-grid-item ten">
            <div className="product">
                <div className="product-inner">
                    <div className="product-thumbnail">
                        <div className="product-thumbnail-inner">
                            <a href={productLinkUrl} target="_parent">
                                <div>
                                    <img
                                        src={product.thumbnailWithPlaceholders || product.filesId.thumbnail}
                                        alt="thumbnail"
                                        style={{ width: CANVAS_WIDTH }}
                                    />
                                    {!product.thumbnailWithPlaceholders && <canvas id={"canvas" + product._id} />}
                                </div>
                            </a>
                        </div>
                    </div>

                    <div className="product-info-wrapper px-2">
                        <div className="product-nameprice">
                            <div className="product-name">{product.name}</div>
                            <div className="product-price">${product.price}</div>
                        </div>

                        <div className="d-flex align-items-center">
                            <Tooltip
                                target={`tooltip-${product._id}`}
                                text={
                                    !userWPId
                                        ? "You must be logged to like this"
                                        : wishlistItems[product._id]
                                        ? "Remove from whishlist"
                                        : "Add to whishlist"
                                }
                            />
                            <div
                                id={`tooltip-${product._id}`}
                                onClick={wishlistItems[product._id] ? handleRemoveFromWishlist : handleAddToWishlist}
                                className="whishlist-btn-item"
                            >
                                {heartIconLoading ? <IconLoading /> : <LikeIcon liked={wishlistItems[product._id]} />}
                            </div>

                            <Tooltip target={`tooltip-edit-${product._id}`} text="Edit" />

                            {/* edit item icon button */}
                            <a
                                id={`tooltip-edit-${product._id}`}
                                href={productLinkUrl}
                                target="_parent"
                                className="ml-2 text-dark"
                            >
                                <i className="fa fa-pencil" aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default ProductListItem;
