import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import AppForm from "./form";
import { useCart } from "../../context/cartContext";
import CircleLoader from "../Loader/circleLoader";

const CheckoutModal = ({ checkout, setCheckout }) => {
    const toggleModal = () => setCheckout(false);
    const { cartItems, reloadCart } = useCart();
    const [modalLoading, setModalLoading] = useState(true);

    const cartItemsArray = Object.values(cartItems);

    const totalPrice = cartItemsArray
        .reduce((sum, item) => {
            const customizationData = (() => {
                try {
                    return JSON.parse(item.customizationData);
                } catch (error) {
                    console.error("Invalid JSON in customizationData:", error);
                    return {};
                }
            })();
            const hasMusic = customizationData.song?.name ? true : false;
            const musicCharge = hasMusic ? 5 : 0;
            return sum + (item.productDetails.price + musicCharge) * item.count;
        }, 0)
        .toFixed(2);
    const cartProducts = cartItemsArray.map((item) => ({
        productId: item.product_id,
        price: item.productDetails.price,
        name: item.productDetails.name,
        quantity: 1,
    }));
    const handleFormLoaded = () => {
        setModalLoading(false);
    };
    return (
        <div>
            <Modal isOpen={checkout} toggle={toggleModal}>
                <ModalHeader>Checkout</ModalHeader>
                {modalLoading && (
                    <div className="spinner-overlay">
                        <CircleLoader color="#000" loading={modalLoading} size={50} />
                    </div>
                )}
                <>
                    <ModalBody className="modal" style={{ display: modalLoading ? "none" : "block" }}>
                        <div className="cart-payment-container">
                            <p className="payment-hd">Payment Details</p>
                            <div className="cart-payment-form">
                                <div className="cart-input-wrap">
                                    <AppForm
                                        total={totalPrice}
                                        products={cartProducts}
                                        onFormLoaded={handleFormLoaded}
                                        closeModal={toggleModal}
                                    />
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </>
            </Modal>
        </div>
    );
};

export default CheckoutModal;
