import ajax from "./ajax";
import { API_BASE_URL } from "../config";

const CouponsRequest = {};


CouponsRequest.getCoupons = async function () {
    
    const url = `${API_BASE_URL}/coupons/list`;

    const couponsresult = await ajax.get(url);
    return couponsresult;
};

CouponsRequest.getCouponsbycode = async function (code) {
    
    const url = `${API_BASE_URL}/coupons/couponsbycode/${code}`;

    const couponsresult = await ajax.get(url);
    return couponsresult;
};


export default CouponsRequest;