import React from 'react';
import './style/downloads.css';
import Downloadspdf from './Downloadspdf'
import Downloadsjpg from './Downloadsjpg'


const Downloads = () => {

    // dp = download page
    return (
        <div className="dp__container downloads-class page-class">
            <div className="dp__title">Download your card</div>

            <div className="dp__buttons">
                {/* pdf button */}
                <Downloadspdf/>
                {/* jpg button */}
                <Downloadsjpg/>
            </div>
        </div>
        
    );
}

export default Downloads;



// import React, { useEffect, useState } from 'react';
// // custom components
// import Loader from '../Loader/customLoader';
// import { Spinner } from 'reactstrap'
// // utils
// import { getUrlQuery } from '../../utils/url';
// import {
//     initCanvas,
//     createPDFAndDownloadV2,
//     generateVideoAndGetURL,
//     getSmallerCanvasBase64ImageForJPEG,
//     // getPosterbase64Image
// } from '../../utils/handleCreateFiles';
// import { forceRenderUpdate } from '../../utils/canvas';
// import { downloadVideo, downloadImage } from '../../utils/downloadManager';
// import saveAutocompleteValues from '../../utils/saveAutoCompleteValues';
// import isMobile from '../../utils/detectMobile';
// // import emulateMessageDebugger from '../../utils/emulateDebugOnIframe';
// // requests
// import orderRequests from '../../requests/orders';
// //style
// import './style/downloads.css';
// // images
// import imageIcon from '../../assets/images/icons/image-icon.svg';
// import pdfIcon from '../../assets/images/icons/file-icon.svg';
// import videoIcon from '../../assets/images/icons/videocam-icon.svg';
// import { useLocation } from 'react-router-dom';
// // import mockdata from '../../mocks/order_data_mockup';
// // import mockdata from '../../mocks/mockdata';
// // import mockdata from '../../mocks/mockdata2';

// const Downloads = () => {
//     const [orderItemData, setOrderItemData] = useState({});
//     // const [pdfSizes, setPdfSizes] = useState([]);
//     const [pdfTagSize, setPdfTagSize] = useState(null);
//     const [dataLoading, setDataLoading] = useState(true);
//     const [videoUrlCreated, setVideoUrlCreated] = useState('');
//     const [isCreatingVideo, setIsCreatingVideo] = useState(false);
//     const [previewImageState, setPreviewImageState] = useState({ loading: true, imageUrl: '' });
//     const location = useLocation();
    
//     // get order data
//     useEffect(() => {
//         // const { order_id, order_item_id, cart_item_key } = getUrlQuery();
//         // console.log(order_id, order_item_id,cart_item_key, "order_id, order_item_id, cart_item_key");

//         const queryParams = new URLSearchParams(location.search);
//         const productId = queryParams.get('productId');
//         const orderId = queryParams.get('orderId');

//         // if (order_item_id) {
//         orderRequests.getOrderItem({
//             // woo_cart_item_key: cart_item_key,
//             // woo_order_id: order_id,
//             // woo_order_item_id: order_item_id
//             productId,
//             orderId
//         }).then(data => {
//             setOrderItemData(data);
//             setDataLoading(false);
//             console.log(data, "data");

//             const { _id, productId, orderId } = data;
//             const { QRText, canvasObjects, productFilesId, productName } = data.customization_data;
//              console.log(_id ,"_id ");

//             //  init canvas with original image
//             initCanvas({
//                 canvasObjects,
//                 QRText,
//                 productId: _id
//                 // product: { productFilesId, productName }
//             }).then(async (tempCanvas) => {
//                 await forceRenderUpdate(tempCanvas, true, false, true);
//                 console.log('canvas initialized');
//                 const previewImgBase64 = tempCanvas.toDataURL({
//                     format: 'jpeg'
//                 });
//                 console.log( previewImgBase64, "previewImgBase6411");

//                 setPreviewImageState({ loading: false, imageUrl: previewImgBase64 });

//                 saveAutocompleteValues(tempCanvas)
//                     .then(() => console.log('autocomplete values saved'))
//                     .catch((err) => console.log('Saving autocomplete values failed', err));
//             })
//         })

//         // // for testing on localhost
//         // const data = mockdata.data;
//         // setOrderItemData(data);
//         // setDataLoading(false);
//         // const { QRText, canvasObjects, productFilesId, productName } = data.customization_data;
//         // // init canvas with original image
//         // initCanvas({
//         //     canvasObjects,
//         //     QRText,
//         //     product: { productFilesId, productName }
//         // }).then(async (tempCanvas) => {
//         //     await forceRenderUpdate(tempCanvas, true, false, true);

//         //     console.log('canvas initialized');
//         //     const previewImgBase64 = tempCanvas.toDataURL({
//         //         format: 'jpeg'
//         //     });

//         //     setPreviewImageState({ loading: false, imageUrl: previewImgBase64 });
//         // })
//         // }
//     }, []);

//     // set poster sizes based on tags
//     useEffect(() => {
//         if (orderItemData?.customization_data) {
//             const tags = orderItemData?.customization_data?.tags || [];
//             if (tags.length) {
//                 const tagDimension = tags.join(' ').match(/\d+\.?\d+x\d+\.?\d+/i); // dimensions like 8.5x11 or 11x8.5
//                 if (tagDimension) {
//                     const [width, height] = tagDimension[0].split(/x/i).map(Number);
//                     setPdfTagSize({ width, height });
//                 }
//             }
//         }
//     }, [orderItemData]);

//     const downloadAs = async (format) => {
//         console.log();

//         if (previewImageState.loading) {
//             return;
//         }
//         const productName = orderItemData?.customization_data?.productName || 'file';
//         console.log(productName ,"productName");
        
//         try {
//             let base64Image;
//             if (format === 'jpeg' || format === 'png') {
//                 // Retrieve the image as base64 from the canvas
//                 base64Image = await getSmallerCanvasBase64ImageForJPEG(format);
//                 // Mobile handling (iOS)
//                 if (isMobile.iOS()) {
//                     downloadImage(base64Image, productName, format);
//                 } else {
//                     let img = base64Image;
//                     const a = document.createElement('a');
//                     a.download = productName + '.' + format;
//                     a.href = img;
//                     a.click();
//                 }
//                 return;
//             }
//         } catch (error) {
//             console.error(err.message);
//         }
//         if (format === 'video') {
//             if (isCreatingVideo) return;
//             try {
//                 if (!videoUrlCreated) {
//                     setIsCreatingVideo(true);
//                     const imgNameExt = orderItemData?.customization_data?.watermarkImage || 'image.jpg';
//                     const song = orderItemData?.customization_data?.song || null;
//                     const videoUrl = await generateVideoAndGetURL(imgNameExt, song);
//                     // actually download
//                     await downloadVideo(videoUrl, productName);
//                     setVideoUrlCreated(videoUrl);
//                     setIsCreatingVideo(false);
//                 }
//                 else {
//                     // actually download
//                     await downloadVideo(videoUrlCreated, productName);
//                 }

//             } catch (err) {
//                 console.error('Error creating video', err.message);
//                 setIsCreatingVideo(false);
//             }

//         }
//     }
//     const downloadAsPdf = () => {
//         const productName = orderItemData?.customization_data?.productName || 'download';
//         createPDFAndDownloadV2(productName, pdfTagSize);
//     }

//     // dp = download page
//     return (
//         <div className="dp__container downloads-class page-class">
//             <div className="dp__title">Download your card</div>

//             <div className="dp__buttons">
//                 <div id="dp__download-overlay" style={{ display: previewImageState.loading ? 'flex' : 'none' }}>
//                     <Loader width={100} height={100} />
//                 </div>
//                 {/* pdf button */}
//                 <div className="dp__btn-item">
//                     <div
//                         className={`dp__format-button ${previewImageState.loading ? 'disabled' : ''}`}
//                         onClick={downloadAsPdf}
//                     >
//                         <img src={pdfIcon} alt="pdf icon" className="dp_icon_btn_img" />
//                         <div className="dp__format-text">PDF</div>
//                     </div>
//                     <div className="dp__btn-item-description">
//                         for print
//                     </div>
//                 </div>
//                 {/* jpg button */}
//                 <div className="dp__btn-item">
//                     <div
//                         onClick={() => downloadAs('jpeg')}
//                         className={`dp__format-button ${previewImageState.loading ? 'disabled' : ''}`}
//                     >
//                         <img src={imageIcon} alt="png icon" width={25} />
//                         <div className="dp__format-text">JPG</div>
//                     </div>
//                     <div className="dp__btn-item-description">
//                         for screens
//                     </div>
//                 </div>

//                 {/* video button */}
//                 {
//                     orderItemData?.customization_data?.song && (
//                         <div className="dp__btn-item">
//                             <div
//                                 className={`dp__format-button ${(previewImageState.loading || isCreatingVideo) ? 'disabled' : ''}`}
//                                 onClick={() => downloadAs('video')}
//                             >
//                                 <img src={videoIcon} alt="video icon" className="dp_icon_btn_img" />
//                                 <div className="dp__format-text">MP4</div>

//                                 {
//                                     isCreatingVideo && (
//                                         <div id="video-load-overlay"> <Spinner type="border" style={{ color: '#af40f7' }} /> </div>
//                                     )
//                                 }
//                             </div>

//                             <div className="dp__btn-item-description">
//                                 Video
//                             </div>
//                         </div>
//                     )
//                 }
//             </div>
//             <canvas id="your-canvas-id" width="400" height="400"></canvas>

//         </div>
        
//     );
// }

// export default Downloads;

/// nwew data
// import React, { useEffect, useState } from 'react';
// // utils
// import { getUrlQuery } from '../../utils/url';
// import { assingObjectsToCanvas, forceRenderUpdate, setCanvasAtResoution, setCustomFontsWhenLoaded, toggleAllFieldsVisibility } from '../../utils/canvas';
// import orderRequests from '../../requests/orders';
// //style
// import './style/downloads.css';
// // images
// import imageIcon from '../../assets/images/icons/image-icon.svg';
// import pdfIcon from '../../assets/images/icons/file-icon.svg';
// import { useLocation } from 'react-router-dom';
// import productRequests from '../../requests/products';
// import CustomLoader from '../Loader/customLoader';
// import jsPDF from 'jspdf';
// import downloadsjpg from '../Downloads/downloadsjpg'
// import downloadspdf from '../Downloads/downloadspdf'


// const Downloads = () => {
//     const location = useLocation();

//     const [canvas, setCanvas] = useState();
//     const [inputsproducts, setInputsproducts] = useState({});
//     const [isLoading, setIsLoading] = useState(false);
//     const [enablePreview, setEnablePreview] = useState(false);
//     const [productimage, setproductimage] = useState();
//     const [productDownloadId, setProductDownloadId] = useState();
//     const [productdata, setProductdata] = useState();
//     const [InputsValues, setInputsValues] = useState();
//     const [image ,setimage] = useState();
    
//     // const useQuery = () => {
//     // return new URLSearchParams(useLocation().search);
//     // };
//     // const query = useQuery();
//     // const cart_item_key = query.get("cart_item_key");

//     const queryParams = new URLSearchParams(location.search);
//     const productId = queryParams.get('productId');
//     const orderId = queryParams.get('orderId');

//     useEffect(() => {
//         if (productDownloadId == null) return;

//         // console.log(productDownloadId, "image");

//         const CANVAS_SIZE = 800;

//         const _canvas = new fabric.Canvas("canvas-wrapper", {
//             selection: false,
//             imageSmoothingEnabled: false,
//         });
//         setCanvas(_canvas);

//         async function _getProductFields() {
//             let _productFields;
//             console.log(_productFields, "image");

//             const updatadata = await orderRequests.getOrderItem({
//                 productId: productDownloadId || customizer_product_id,
//                 orderId: orderId,
//             });
//                 const customizationDataString = updatadata.customization_data;
//                 const customizationData = JSON.parse(customizationDataString);
//                 _productFields = customizationData.canvasObjects;
            
//             return _productFields;
//         }


//         async function resizeAndSetTextOnCanvas() {
//             const _productFields = await _getProductFields();
//             if (_productFields === undefined || _productFields.length === 0) return false;

//             const imageDimensions = {
//                 width: _productFields[0].originalImageWidth,
//                 height: _productFields[0].originalImageHeight,
//             };

//             const _inputsValues = {};
//             for (let f of _productFields) {
//                 _inputsValues[f._id] = f;
//             }

//             setInputsValues(_inputsValues);
//             await assingObjectsToCanvas(_canvas, _productFields);
//             toggleAllFieldsVisibility(_canvas, false);
//             setCanvasAtResoution(_canvas, CANVAS_SIZE, _productFields[0].canvasWidth, imageDimensions);
//             return true;
//         }

//         function setCanvasBackgroundImage(_productData) {
//             return new Promise((resolve, reject) => {
//                 if (!_productData || !(_productData.filesId && _productData.filesId.pathWithWatermark)) {
//                     reject(new Error("Invalid product data"));
//                     return;
//                 }
//                 fabric.Image.fromURL(
//                     _productData.filesId.pathWithWatermark,
//                     (img) => {
//                         const scaleFactor = img.width / _canvas.width;
//                         const naturalCanvasHeight = img.height / scaleFactor;
//                         _canvas.setDimensions({ width: _canvas.width, height: naturalCanvasHeight });
//                         _canvas.setBackgroundImage(img, _canvas.renderAll.bind(_canvas), {
//                             scaleX: _canvas.width / img.width,
//                             scaleY: _canvas.height / img.height,
//                         });
//                         resolve();
//                     },
//                     {
//                         crossOrigin: "anonymous",
//                         objectCaching: false,
//                     }
//                 );
//             });
//         }

//         async function getProductData() {
//             const { customizer_product_id, id: wooProductId } = productDownloadId ? {} : getUrlQuery();
//             const _product = await productRequests.getProduct({
//                 productId: productDownloadId || customizer_product_id,
//                 wooProductId,
//             });
          
//             // console.log(_WProduct, "result");
//             console.log(_product,"_product");
            
//             if (!_product) {
//                 throw new Error("Failed to fetch product data");
//             }
//             setProductdata(_product);
//             setIsLoading(true);
//             return _product;
//         }

//         getProductData().then(async (_productData) => {
//             await resizeAndSetTextOnCanvas();
//             await setCustomFontsWhenLoaded(_canvas);
//             await forceRenderUpdate(_canvas);
//             try {
//                 await setCanvasBackgroundImage(_productData);
//                 forceRenderUpdate(_canvas, true);
//                 setEnablePreview(true);
//                 toggleAllFieldsVisibility(_canvas);
//                 setIsLoading(false);

//                 handleDownload(_canvas);
//                 // handleDownloadPDF(_canvas);

//                 setProductDownloadId(null);
//             } catch (error) {
//                 console.error("Error setting background image:", error);
//             }
//         }).catch((error) => {
//             console.error("Error fetching product data:", error);
//         });
//         return () => {
//             if (canvas) {
//                 canvas.dispose();
//                 setCanvas(null);
//             }
//         };

//     }, [productDownloadId]);

//     const handleDownload = (canvas) => {
//         if (!canvas) return;
//         const dataUrl = canvas.toDataURL("image/png");
//         const downloadLink = document.createElement("a");
//         downloadLink.href = dataUrl;
//         downloadLink.download = `product_${productDownloadId}.png`;
//         downloadLink.click();
//         setimage(dataUrl)
//     };


//     const handleDownloadPDF = (canvas) => {
//         if (!canvas) return;
//         const dataUrl = canvas.toDataURL('image/png');
//         const pdf = new jsPDF('portrait', 'mm', 'a4'); 
//         const pageWidth = pdf.internal.pageSize.getWidth(); 
//         const pageHeight = pdf.internal.pageSize.getHeight();     
//         pdf.addImage(dataUrl, 'PNG', 0, 0, pageWidth, pageHeight);
//         pdf.save(`product_${productDownloadId}.pdf`);
//     };
    
//     const DownloadJPG = (e, product) => {
//         e.preventDefault();
//         setProductDownloadId(product);
//     };
//     const downloadAsPDF =()=>{
//         e.preventDefault();
//         setProductDownloadId(product);
//     }

  

//     // dp = download page
//     return (
//         <div className="dp__container downloads-class page-class">
//             <div className="dp__title">Download your card</div>

//             <div className="dp__buttons">
//                 {/* pdf button */}
//                 <div className="dp__btn-item">
//                     <div
//                         className={`dp__format-button `}
//                         // onClick={(e) => downloadAsPDF()}
//                     >
//                         <img src={pdfIcon} alt="pdf icon" className="dp_icon_btn_img" />
//                         <div className="dp__format-text">PDF</div>
//                         {
//                             isLoading && <div style={{ width: "100%", height: "15px", display: "flex", alignItems: "center" }}>
//                                 <CustomLoader />
//                             </div>
//                         }
//                     </div>
//                     <div className="dp__btn-item-description">
//                         for print
//                     </div>
//                 </div>
//                 {/* jpg button */}
//                 <div className="dp__btn-item">
//                     <div
//                         onClick={(e) => DownloadJPG(e, productId)}
//                         className={`dp__format-button`}>
//                         <img src={imageIcon} alt="png icon" width={25} />
//                         <div className="dp__format-text">JPG</div>
//                         {
//                             isLoading && <div style={{ width: "100%", height: "15px", display: "flex", alignItems: "center" }}>
//                                 <CustomLoader />
//                             </div>
//                         }
//                     </div>
//                     <div className="dp__btn-item-description">
//                         for screens
//                     </div>
//                 </div>
//             </div>
//         </div>
        
//     );
// }

// export default Downloads;
