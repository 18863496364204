import React, { createContext, useContext, useEffect, useState, useReducer } from "react";
// requests
import wishlistRequests from "../requests/wishlist";
import productRequests from "../requests/products";
// utils
import { getUrlQuery } from "../utils/url";

import { isCustomerLogin } from "../utils/common";

const WhislistContext = createContext();

// action reducer types
const ADD_TO_WHISHLIST = "add-to-wishlist";
const REMOVE_FROM_WHISHLIST = "remove-from-wishlist";
const RESTORE_WHISHLIST = "restore-wishlist";
const VOID_WHISHLIST = "void-wishlist";

// wishlist state structure is like this
// {
//     items: { [itemId]: item, ... },
//     totalItemsQty: number
// }
function wishlistReducer(state, action) {
    switch (action.type) {
        case ADD_TO_WHISHLIST: {
            const { itemId, item } = action.payload;
            return {
                items: {
                    ...state.items,
                    [itemId]: item,
                },
                totalItemsQty: state.totalItemsQty + 1,
            };
        }

        // restore cart if user reload the page
        case RESTORE_WHISHLIST: {
            const wishlistData = action.payload;
            return { ...wishlistData };
        }

        case REMOVE_FROM_WHISHLIST: {
            const { itemId } = action.payload;
            const stateCopy = { ...state };

            delete stateCopy.items[itemId];

            return {
                ...stateCopy,
                totalItemsQty: state.totalItemsQty - 1,
            };
        }

        case VOID_WHISHLIST:
            return { items: {}, totalItemsQty: 0 };

        default:
            return state;
    }
}

const WishlistProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(null);
    const [state, dispath] = useReducer(wishlistReducer, { items: {}, totalItemsQty: 0 });

    useEffect(() => {
        // const { user_id } = getUrlQuery();
        const customerId = isCustomerLogin();

        if (!customerId) {
            setIsLoading(false);
            return;
        }

        async function getItemsFromWP() {
            const data = await wishlistRequests.getUserWishlist({ customerId });
            return data || [];
        }

        async function getProductsByWooId(wooIdsArray) {
            const products = await productRequests.getProductsByWooId(wooIdsArray);
            return products;
        }

        function parseProductsToStore(products) {
            const _state = { items: {}, totalItemsQty: 0 };
            for (const p of products) {
                _state.items[p._id] = p;
                _state.totalItemsQty++;
            }
            // console.log("restored wislist store");

            dispath({
                type: RESTORE_WHISHLIST,
                payload: _state,
            });
        }

        // start requests
        getItemsFromWP()
            .then((ids) => {
                getProductsByWooId(ids).then((_products) => {
                    parseProductsToStore(_products);
                    setIsLoading(false);
                });
            })
            .catch((err) => {
                console.log("Error getting and parsing wishlist items", err.message);
                setIsLoading(false);
            })
            .finally(() => {
                setHasError(null);
            });
    }, []);

    return (
        <WhislistContext.Provider
            value={{
                isLoading,
                hasError,
                state,
                dispath,
            }}
        >
            {children}
        </WhislistContext.Provider>
    );
};

// custom hook
function useWishlist() {
    const { state, dispath, isLoading } = useContext(WhislistContext);

    function addItemToWishlist(item) {
        if (!item) return;

        const { _id } = item;
        dispath({
            type: ADD_TO_WHISHLIST,
            payload: { itemId: _id, item },
        });

        // console.log("Wishlist: " + JSON.stringify(state.items));
    }

    function removeFromWishlist(item) {
        if (!item) return;

        const { _id } = item;
        dispath({
            type: REMOVE_FROM_WHISHLIST,
            payload: { itemId: _id},
        });

        // console.log("Wishlist: " + JSON.stringify(state.items));
    }

    function voidWishlist() {
        dispath({
            type: VOID_WHISHLIST,
        });

        // console.log("Wishlist: " + JSON.stringify(state.items));
    }

    return {
        addItemToWishlist,
        removeFromWishlist,
        voidWishlist,
        wishlistItems: state.items,
        wishlistQty: state.totalItemsQty,
        isLoading,
    };
}

export { WishlistProvider, useWishlist };
