import React, { createContext, useContext, useEffect, useState, useReducer } from "react";
import cartRequests from "../requests/cart";
import { isCustomerLogin } from "../utils/common";

const CartContext = createContext();

const ADD_TO_CART = "add-to-cart";
const REMOVE_FROM_CART = "remove-from-cart";
const RESTORE_CART = "restore-cart";
const VOID_CART = "void-cart";

function cartReducer(state, action) {
    switch (action.type) {
        case ADD_TO_CART: {
            const { itemId, item } = action.payload;
            if (state.items[itemId]) {
                return state;
            }
            return {
                items: { ...state.items, [itemId]: item },
                totalItemsQty: state.totalItemsQty + 1,
            };
        }
        case REMOVE_FROM_CART: {
            const { itemId } = action.payload;
            const updatedItems = { ...state.items };
            delete updatedItems[itemId];
            return {
                items: updatedItems,
                totalItemsQty: state.totalItemsQty - 1,
            };
        }
        case RESTORE_CART: {
            return { ...action.payload };
        }
        case VOID_CART:
            return { items: {}, totalItemsQty: 0 };
        default:
            return state;
    }
}

const CartProvider = ({ children }) => {
    const [state, dispatch] = useReducer(cartReducer, { items: {}, totalItemsQty: 0 });
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(null);

    async function getItemsFromCart() {
        const customerId = isCustomerLogin();
        try {
            const response = customerId
                ? await cartRequests.getFromCart(customerId)
                : await cartRequests.getFromCartGuest(localStorage.getItem("guest_user_id"));

            return response.data || [];
        } catch (error) {
            console.error("Error fetching cart items:", error);
            throw error;
        }
    }

    async function reloadCart() {
        setIsLoading(true);
        try {
            const data = await getItemsFromCart();
            dispatch({
                type: RESTORE_CART,
                payload: {
                    items: data.reduce((acc, item) => ({ ...acc, [item._id]: item }), {}),
                    totalItemsQty: data.length,
                },
            });
        } catch (error) {
            setHasError("Failed to load cart items.");
        } finally {
            setIsLoading(false);
        }
    }

    async function saveInCart() {
        const customerId = isCustomerLogin();
        const guestUserId = localStorage.getItem("guest_user_id");

        if (!customerId || !guestUserId) return;
        if (customerId) {
            try {
                const response = await cartRequests.updateCartCustomerId({ guestUserId, customerId });
                if (response.success) {
                    localStorage.removeItem("guest_user_id");
                } else {
                    console.error("Failed to associate guest cart:", response.message);
                }
            } catch (error) {
                console.error("Error saving cart:", error);
            }
        } else {
            console.error("Error saving cart: customerId not found");
        }
    }

    useEffect(() => {
        saveInCart();
        reloadCart();
    }, []);

    return (
        <CartContext.Provider
            value={{
                cartItems: state.items,
                cartQty: state.totalItemsQty,
                isLoading,
                hasError,
                addItemToCart: (item) => {
                    dispatch({ type: ADD_TO_CART, payload: { itemId: item._id, item } });
                },
                removeFromCart: (item) => {
                    dispatch({ type: REMOVE_FROM_CART, payload: { itemId: item._id } });
                },
                voidCart: () => {
                    dispatch({ type: VOID_CART });
                },
                reloadCart,
            }}
        >
            {children}
        </CartContext.Provider>
    );
};

function useCart() {
    return useContext(CartContext);
}

export { CartProvider, useCart };
