import React, { useState, useEffect, useMemo } from "react";
import ModalCancelIcon from "../../assets/icons/modal-cancel-icon";
import EyeIcon from "../../assets/icons/EyeIcon";
import EditIcon from "../../assets/icons/PencilIcon";
import { Link, useNavigate } from "react-router-dom";
import cartRequests from "../../requests/cart";
import { isCustomerLogin } from "../../utils/common";
import CheckoutModal from "../Checkout/checkoutModal";
import "./style.css";

import { useCart } from "../../context/cartContext";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const CartMenu = ({ isCartMenuOpen, setIsCartMenuOpen }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [canvasData, setCanvasData] = useState();
    const [modal, setModal] = useState(false);
    const [imgData, setImgData] = useState("");
    const [checkout, setCheckout] = useState(false);

    const { cartItems, cartQty, removeFromCart } = useCart();
    const navigate = useNavigate();

    const handleEdit = (item) => {
        navigate(`/card/${item.product_id}/?cart_item_key=${item._id}`);
    };

    const handleDelete = async (item) => {
        try {
            await cartRequests.deleteFromCart(item._id);
            removeFromCart(item);
        } catch (error) {
            console.error("Error deleting cart item:", error);
        }
    };

    const handlePreview = (customizationData) => {
        const imgData = customizationData;
        setImgData(imgData);
        setModal(true);
    };
    const toggleModal = () => setModal(!modal);

    const handleCheckout = () => {
        setIsCartMenuOpen(false);
        setCheckout(true);
    };

    return (
        <div>
            {Object.keys(cartItems ?? {}).length !== 0 ? (
                <div
                    className={`${
                        isCartMenuOpen === true
                            ? "active"
                            : isCartMenuOpen === null
                            ? "display-none"
                            : isCartMenuOpen === false
                            ? "hidden"
                            : ""
                    } cart-menu-container`}
                >
                    <div
                        className="close-cart-menu-icon"
                        onClick={() => {
                            setIsCartMenuOpen(false);
                        }}
                    >
                        <ModalCancelIcon />
                    </div>

                    <div className="cart-menu-sub-container">
                        <div className="shopping-cart-hd-wrap">
                            <p className="shopping-cart-hd">Shopping Cart</p>
                        </div>
                        <div className="d-flex flex-column pb-5">
                            {Object.keys(cartItems)?.map((key, index) => {
                                const item = cartItems[key];

                                return (
                                    <div key={index} className="cart-menu-item-wrap">
                                        <img
                                            className="cart-menu-img"
                                            src={
                                                item.customizeItemPreview || item.productDetails.customized_item_preview
                                            }
                                            alt="Item preview"
                                        />
                                        <div className="cart-menu-data-wrap">
                                            <div className="cart-menu-text">
                                                <p className="cart-menu-item-data">
                                                    <p className="cart-menu-item-name">{item.productDetails.name}</p>
                                                    <span className="cart-menu-item-hd">Music: </span>{" "}
                                                    {item.customizationData &&
                                                    JSON.parse(item.customizationData).song?.name
                                                        ? JSON.parse(item.customizationData).song.name
                                                        : "No"}
                                                </p>
                                                <p className="cart-menu-item-data">
                                                    <span className="cart-item-hd">QR: </span>
                                                    {item.customizationData && JSON.parse(item.customizationData).QRText
                                                        ? JSON.parse(item.customizationData).QRText
                                                        : "No"}
                                                </p>
                                                <p className="cart-menu-item-data">
                                                    <span className="cart-menu-item-hd">Size: </span>
                                                    {item.customizationData &&
                                                    JSON.parse(item.customizationData).dimensions
                                                        ? JSON.parse(item.customizationData).dimensions
                                                        : "N/A"}
                                                </p>
                                                <p className="cart-menu-item-price">
                                                    $
                                                    {(
                                                        (item.productDetails.price +
                                                            (item.customizationData &&
                                                            JSON.parse(item.customizationData).song?.name
                                                                ? 5
                                                                : 0)) *
                                                        item.count
                                                    ).toFixed(2)}
                                                </p>
                                            </div>
                                            <div className="cart-menu-icon-wrap">
                                                <div
                                                    className="cart-menu-eye-icon"
                                                    onClick={() => handlePreview(item.customizeItemPreview)}
                                                >
                                                    <EyeIcon width={"1em"} height={"1em"} />
                                                </div>
                                                <div
                                                    className="cart-menu-edit-icon"
                                                    onClick={() => {
                                                        handleEdit(item);
                                                    }}
                                                >
                                                    <EditIcon width={"10px"} height={"10px"} />
                                                </div>
                                                <div
                                                    className="cart-menu-delete-icon"
                                                    onClick={() => {
                                                        handleDelete(item);
                                                    }}
                                                >
                                                    <i class="fa fa-trash" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="cart-menu-btn-wrap">
                        <button className="btn" onClick={handleCheckout}>
                            Continue to checkout
                        </button>
                    </div>
                </div>
            ) : (
                <div
                    className={`${!isCartMenuOpen ? "hidden" : "active"} cart-menu-container`}
                    style={{ height: "200px" }}
                >
                    <div
                        className="close-cart-menu-icon"
                        onClick={() => {
                            setIsCartMenuOpen(false);
                        }}
                    >
                        <ModalCancelIcon />
                    </div>

                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                        <p>No products in the cart.</p>
                    </div>
                </div>
            )}

            <Modal isOpen={modal} toggle={toggleModal}>
                <ModalBody>
                    {imgData && <img src={imgData} alt="Customization Preview" style={{ width: "100%" }} />}
                </ModalBody>
                <ModalFooter></ModalFooter>
            </Modal>
            {checkout && <CheckoutModal checkout={checkout} setCheckout={setCheckout} cartItems={cartItems} />}
        </div>
    );
};

export default CartMenu;
