import React, { useEffect, useRef, useState } from "react";
import WrappedIfield from "./wrapped-ifield";
import { CARD_TYPE, CVV_TYPE } from "@cardknox/react-ifields";
import { format } from "date-fns";
import { API_BASE_URL } from "../../config";
import { getCustomerID } from "../../utils/token";
import CouponsRequest from "../../requests/Coupons";
import { isCustomerLogin, customerEmail } from "../../utils/common";
import cartRequests from "../../requests/cart";
import Swal from "sweetalert2";
import customer from "../../requests/customer";
import { useCart } from "../../context/cartContext";
export default function AppForm({ total, products, onFormLoaded, closeModal }) {
    const isCustomer = isCustomerLogin();
    const isCustomerEmail = customerEmail() || "";
    const [cardToken, setCardToken] = useState("");
    const [cvvToken, setCvvToken] = useState("");
    const [issuer, setIssuer] = useState("");
    const [amount, setAmount] = useState(2.36);
    const [firstName, setFirstName] = useState("John");
    const [lastName, setLastName] = useState("Doe");
    const [address, setAddress] = useState("123 Somewhere");
    const [city, setCity] = useState("Anywhere");
    const [addressState, setAddressState] = useState("NY");
    const [zip, setZip] = useState("98765");
    const [mobile, setMobile] = useState("1234567890");
    const [email, setEmail] = useState(isCustomerEmail);
    const [expiryDate, setExpiryDate] = useState(""); // MM/YY format
    const [gatewayResponse, setGatewayResponse] = useState("");
    const [password, setPassword] = useState("");
    const [emailExists, setEmailExists] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const { voidCart } = useCart();

    const cardRef = useRef();
    const cvvRef = useRef();
    const couponcode = useRef();
    useEffect(() => {
        const iframe = document.querySelector("iframe.ifields");
        if (iframe) {
            iframe.onload = () => {
                if (onFormLoaded) {
                    onFormLoaded();
                }
            };
        } else {
            if (onFormLoaded) {
                onFormLoaded();
            }
        }
    }, [onFormLoaded]);

    const checkEmailInDatabase = async (email) => {
        try {
            const response = await customer.checkEmail(email);

            console.log(response, "data");

            if (response.exists) {
                setEmailExists(true);
                setUserInfo(response.user);
                setEmail(response.user.email);
            } else {
                setEmailExists(false);
                setUserInfo(null);
            }
        } catch (error) {
            console.error("Error checking email:", error);
        }
    };
    const handleEmailChange = async (e) => {
        const emailValue = e.target.value;
        setEmail(emailValue);

        if (emailValue) {
            await checkEmailInDatabase(emailValue);
        }
    };
    // console.log(couponcode, "couponcode");
    // console.log(cvvRef,"cvvRef");

    const handleCardToken = (data) => {
        setCardToken(data.xToken);
    };

    const handleCvvToken = (data) => {
        setCvvToken(data.xToken);
    };

    const handleExpiryDateChange = (e) => {
        let input = e.target.value.replace(/\D/g, "");
        if (input.length > 2) {
            input = input.slice(0, 2) + "/" + input.slice(2);
        }
        setExpiryDate(input);
    };

    const submitToGateway = async (e) => {
        e.preventDefault();

        try {
            Swal.fire({
                title: "Processing Payment...",
                html: "Please wait while we process your payment.",
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            let Couponsdata = null;
            if (couponcode && couponcode.current.value) {
                Couponsdata = await CouponsRequest.getCouponsbycode(couponcode.current.value);
            }

            await cardRef.current.getToken();
            await cvvRef.current.getToken();
            const tokenWaiter = new Promise((resolve, reject) => {
                let attempts = 0;
                const interval = setInterval(() => {
                    console.log(cardToken, "cardToken");
                    if (cardToken && cvvToken) {
                        clearInterval(interval);
                        resolve();
                    } else if (attempts > 10) {
                        // Retry for ~1 second (100ms * 10)
                        clearInterval(interval);
                        reject(new Error("Failed to retrieve tokens in time"));
                    }
                    attempts++;
                }, 500); // Check every 100ms
            });

            await tokenWaiter;

            console.log(cardToken, "cardToken");

            if (!cardToken || !cvvToken) {
                Swal.close();
                Swal.fire({
                    title: "Error",
                    icon: "error",
                    text: "Failed to retrieve card or CVV token.",
                    timer: 2000,
                    showConfirmButton: false,
                });
                throw new Error("Failed to retrieve card or CVV token");
            }

            if (Couponsdata) {
                request.XCoupon = Couponsdata._id;
            }
            let customerData = {
                email: email,
                password: password,
                emailExists: emailExists,
                customerId: userInfo ? userInfo._id : null,
                guestId: localStorage.getItem("guest_user_id"),
            };
            if (isCustomer) {
                customerData = {
                    email: email,
                    emailExists: true,
                    customerId: isCustomer,
                    guestId: null,
                };
            }

            let productsdata = null;
            const customerId = isCustomerLogin();
            if (customerId) {
                productsdata = await cartRequests.getFromCart(customerId);
            } else {
                productsdata = await cartRequests.getFromCartGuest(localStorage.getItem("guest_user_id"));
            }

            const totalAmount = products.reduce((acc, item) => acc + item.price * item.quantity, 0).toFixed(2);

            let request = {
                xSoftwareName: "Test-React-iFields",
                xSoftwareVersion: "1.0",
                xVersion: "5.0.0",
                xCommand: "cc:sale",
                xAmount: totalAmount,
                xCardnum: cardToken,
                xBillFirstName: firstName,
                xBillLastName: lastName,
                xBillStreet: address,
                xBillCity: city,
                xBillZip: zip,
                xBillState: addressState,
                xBillMobile: mobile,
                xEmail: email,
                xExp: expiryDate.replace("/", ""),
                xCvv: cvvToken,
                xAllowDuplicate: "TRUE",
            };

            const response = await fetch(`${API_BASE_URL}/cart/process-payment`, {
                method: "POST",
                body: JSON.stringify({
                    card: request,
                    customer: customerData,
                    products: products,
                    canvas: productsdata.data[0],
                }),
                headers: { "Content-Type": "application/json" },
            });

            const responseBody = await response.json();

            setGatewayResponse(responseBody);

            if (responseBody.xError === "" && responseBody.xStatus === "Approved") {
                Swal.fire({
                    title: "Payment Successful",
                    icon: "success",
                    text: "Your payment was processed successfully.",
                    timer: 2000,
                    showConfirmButton: false,
                });
                closeModal();
                voidCart();
                // removeFromCart({ _id: id });

                // Clear cart
                // await cartRequests.clearCart(customerId);
            } else {
                Swal.fire({
                    title: "Payment Error",
                    icon: "error",
                    text: responseBody.xError,
                    timer: 2000,
                    showConfirmButton: false,
                });
            }
        } catch (error) {
            Swal.close();
            Swal.fire({
                title: "Error",
                icon: "error",
                text: error.message,
                timer: 2000,
                showConfirmButton: false,
            });
            setGatewayResponse({ message: error.message });
        }
    };

    useEffect(() => {
        if (gatewayResponse && gatewayResponse.xError !== undefined) {
            if (gatewayResponse.xError === "") {
                Swal.fire({
                    title: "Payment Successfully",
                    icon: "success",
                    text: "Your payment was processed successfully.",
                    timer: 2000,
                    showConfirmButton: false,
                    showCloseButton: false,
                });
            } else {
                Swal.fire({
                    title: "Payment Error",
                    icon: "error",
                    text: gatewayResponse.xError,
                    timer: 2000,
                    showConfirmButton: false,
                    showCloseButton: false,
                });
            }
        }
    }, [gatewayResponse]);

    return (
        <div>
            <section className="hero is-primary">
                <div className="hero-body">
                    <div className="container">
                        <h1 className="title">Checkout</h1>
                        <h4 className="subtitle">Please enter your credit card information</h4>
                    </div>
                </div>
            </section>
            <div className="main">
                <form onSubmit={submitToGateway}>
                    <div className="columns">
                        <div className="column">
                            <section className="box card-box">
                                <div className="field d-none">
                                    <label className="label">Amount</label>
                                    <input
                                        className="input"
                                        type="text"
                                        value={amount}
                                        onChange={(e) => setAmount(e.target.value)}
                                    />
                                </div>
                                {!isCustomer && (
                                    <>
                                        <div>
                                            <label htmlFor="email">Email:</label>
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                value={email}
                                                onChange={handleEmailChange}
                                                required
                                            />
                                        </div>
                                        {!emailExists && (
                                            <div>
                                                <label htmlFor="password">
                                                    Password: <small>(optional)</small>
                                                </label>
                                                <input
                                                    type="password"
                                                    id="password"
                                                    name="password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                            </div>
                                        )}
                                    </>
                                )}

                                <div className="field">
                                    <p className="label">Card Number</p>
                                    <WrappedIfield
                                        ifieldType={CARD_TYPE}
                                        onIssuer={setIssuer}
                                        onToken={handleCardToken}
                                        ref={cardRef}
                                    />
                                </div>

                                <div className="field">
                                    <p className="label">Expiry Date</p>
                                    <input
                                        className="input"
                                        type="text"
                                        placeholder="MM/YY"
                                        value={expiryDate}
                                        onChange={handleExpiryDateChange}
                                        maxLength={5}
                                        required
                                    />
                                </div>

                                <div className="field">
                                    <p className="label"> Card CVV</p>
                                    <WrappedIfield ifieldType={CVV_TYPE} onToken={handleCvvToken} ref={cvvRef} />
                                </div>

                                <div className="field">
                                    <p className="label">Coupons code</p>
                                    <input className="input" type="text" placeholder=" Coupons code" ref={couponcode} />
                                </div>
                            </section>
                        </div>
                    </div>
                    <div className="total-price-container">
                        <p className="total-price">{`Total \$${total}`}</p>
                    </div>

                    <div className="place-order-container">
                        <button className="ogin-button active" type="submit">
                            Place Order
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
