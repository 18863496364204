import React, { useEffect, useState } from 'react'
import { getUrlQuery } from '../../utils/url';
import { assingObjectsToCanvas, forceRenderUpdate, setCanvasAtResoution, setCustomFontsWhenLoaded, toggleAllFieldsVisibility } from '../../utils/canvas';
import orderRequests from '../../requests/orders';
import productRequests from '../../requests/products';
import pdfIcon from '../../assets/images/icons/file-icon.svg';
import CustomLoader from '../Loader/customLoader';
import jsPDF from 'jspdf';


const downloadspdf = () => {


    const [canvas, setCanvas] = useState();
    const [inputsproducts, setInputsproducts] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [enablePreview, setEnablePreview] = useState(false);
    const [productimage, setproductimage] = useState();
    const [productDownloadId, setProductDownloadId] = useState();
    const [productdata, setProductdata] = useState();
    const [InputsValues, setInputsValues] = useState();
    const [image, setimage] = useState();


    const queryParams = new URLSearchParams(location.search);
    const productId = queryParams.get('productId');
    const orderId = queryParams.get('orderId');

    

    useEffect(() => {
        if (productDownloadId == null) return;

        // console.log(productDownloadId, "image");

        const CANVAS_SIZE = 800;

        const _canvas = new fabric.Canvas("canvas-wrapper", {
            selection: false,
            imageSmoothingEnabled: false,
        });
        setCanvas(_canvas);

        async function _getProductFields() {
            let _productFields;
            // console.log(_productFields, "image");

            const updatadata = await orderRequests.getOrderItem({
                productId: productDownloadId || customizer_product_id,
                orderId: orderId,
            });
            console.log(updatadata,"updatadata");
            
                const customizationDataString = updatadata.customization_data;
                const customizationData = JSON.parse(customizationDataString);
                _productFields = customizationData.canvasObjects;
            
            return _productFields;
        }


        async function resizeAndSetTextOnCanvas() {
            const _productFields = await _getProductFields();
            if (_productFields === undefined || _productFields.length === 0) return false;

            const imageDimensions = {
                width: _productFields[0].originalImageWidth,
                height: _productFields[0].originalImageHeight,
            };

            const _inputsValues = {};
            for (let f of _productFields) {
                _inputsValues[f._id] = f;
            }

            setInputsValues(_inputsValues);
            await assingObjectsToCanvas(_canvas, _productFields);
            toggleAllFieldsVisibility(_canvas, false);
            setCanvasAtResoution(_canvas, CANVAS_SIZE, _productFields[0].canvasWidth, imageDimensions);
            return true;
        }

        function setCanvasBackgroundImage(_productData) {
            return new Promise((resolve, reject) => {
                if (!_productData || !(_productData.filesId && _productData.filesId.pathWithWatermark)) {
                    reject(new Error("Invalid product data"));
                    return;
                }
                fabric.Image.fromURL(
                    _productData.filesId.pathWithWatermark,
                    (img) => {
                        const scaleFactor = img.width / _canvas.width;
                        const naturalCanvasHeight = img.height / scaleFactor;
                        _canvas.setDimensions({ width: _canvas.width, height: naturalCanvasHeight });
                        _canvas.setBackgroundImage(img, _canvas.renderAll.bind(_canvas), {
                            scaleX: _canvas.width / img.width,
                            scaleY: _canvas.height / img.height,
                        });
                        resolve();
                    },
                    {
                        crossOrigin: "anonymous",
                        objectCaching: false,
                    }
                );
            });
        }

        async function getProductData() {
            const { customizer_product_id, id: wooProductId } = productDownloadId ? {} : getUrlQuery();
            const _product = await productRequests.getProduct({
                productId: productDownloadId || customizer_product_id,
                wooProductId,
            });
          
            // console.log(_WProduct, "result");
            // console.log(_product,"_product");
            
            if (!_product) {
                throw new Error("Failed to fetch product data");
            }
            setProductdata(_product);
            setIsLoading(true);
            return _product;
        }

        getProductData().then(async (_productData) => {
            await resizeAndSetTextOnCanvas();
            await setCustomFontsWhenLoaded(_canvas);
            await forceRenderUpdate(_canvas);
            try {
                await setCanvasBackgroundImage(_productData);
                forceRenderUpdate(_canvas, true);
                setEnablePreview(true);
                toggleAllFieldsVisibility(_canvas);
                setIsLoading(false);

                handleDownloadPDF(_canvas);

                setProductDownloadId(null);
            } catch (error) {
                console.error("Error setting background image:", error);
            }
        }).catch((error) => {
            console.error("Error fetching product data:", error);
        });
        return () => {
            if (canvas) {
                canvas.dispose();
                setCanvas(null);
            }
        };

    }, [productDownloadId]);


    const handleDownloadPDF = (canvas) => {
        if (!canvas) return;
        const dataUrl = canvas.toDataURL('image/png');
        const pdf = new jsPDF('portrait', 'mm', 'a4'); 
        const pageWidth = pdf.internal.pageSize.getWidth(); 
        const pageHeight = pdf.internal.pageSize.getHeight();     
        pdf.addImage(dataUrl, 'PNG', 0, 0, pageWidth, pageHeight);
        pdf.save(`product_${productDownloadId}.pdf`);
    };


    const downloadAsPDF =(e, product)=>{
        e.preventDefault();
        setProductDownloadId(product);
    }
    return (
        <>
            <div className="dp__btn-item">
                <div
                    className={`dp__format-button `}
                onClick={(e) => downloadAsPDF(e,productId)}
                >
                    <img src={pdfIcon} alt="pdf icon" className="dp_icon_btn_img" />
                    <div className="dp__format-text">PDF</div>
                    {
                        isLoading && <div style={{ width: "100%", height: "15px", display: "flex", alignItems: "center" }}>
                            <CustomLoader />
                        </div>
                    }
                </div>
                <div className="dp__btn-item-description">
                    for print
                </div>
            </div>
        </>
    )
}

export default downloadspdf;
